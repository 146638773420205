import React from 'react'
import loadable from '@loadable/component'
//import React, { useState, useEffect, useRef } from 'react'
//import { Helmet } from 'react-helmet'
import Seo from '../components/SEO'
import metaImage from '../assets/images/rush-promo.jpg'
import Layout from '../components/layout'
import Banner from '../components/Banner'
//import One from '../components/One'
//import Two from '../components/Two'
//import Three from '../components/Three'
//import Four from '../components/Four'

//import Five from '../components/Five'
//import Portfolio from '../components/portfolio'
//import BackImage from '../components/BackImage'
//import Lottie3 from '../components/lottie3'
//import Contact from '../components/Contact'

//import  Animated  from '../components/animated'
//import { Welcome } from '../containers';
//import { useHasBeenVisible } from '../hooks/useVisibility';
//import { useScroll } from '../hooks/useScrolling'
//const Two = loadable(() => import('../components/Two'))
const One = loadable(() => import('../components/One'))
const Four = loadable(() => import('../components/Four'))
const Lottie4 = loadable(() => import('../components/lottie5'))
//const fa = loadable(() => import('../assets/css/fontawesome-all.min.css'))
//import Lottie4 from '../components/lottie5'

  /*const halfPage = useRef();
  const preload = useRef();
  const hasScrolled = useHasBeenVisible(halfPage);
  const isScrolling = useHasBeenVisible(preload);
*/

/*const getScrolled = () => {

}*/

class Home extends React.Component {

	constructor(props) {
	  super(props)

    this.state = {
		userScrolled: false,
    };
	this.setUserScrolled = this.setUserScrolled.bind(this)

//console.log(props);
//console.log(this.state);
}

	setUserScrolled = (value) => {
		//console.log(value)
	  this.setState({
		userScrolled: value,
	  }
	  , function () {
			//console.log(this.state.userScrolled);
		});
	}

  render() {
  //console.log(this.props);
  //console.log(this.props.children);
  //console.log(this.state);
 // const myRef = useRef();
 // const me = myRef.current && myRef.current.getMyState() ? myRef.current.getMyState() : 'no';
 // console.log(me);
  //console.log(myRef.current.getMyState());
  //const scroll = useScroll()
  //Scroll.scrollY > 0 ? const Lottie4 = loadable(() => import('../components/lottie5')) : null

  /*const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      setStartAnimation(true)
    }
	 return () => {
        // this code fires on unmount! Perfect for our use case!
		//setStartAnimation(false)
    }
  }, [startAnimation]);*/

  //const scroll = startAnimation ? true : false
  const scroll = this.state.userScrolled
  //console.log(scroll);
  //console.log(myRef)
    return (
      <Layout location="/">
		<Seo
			title='Κατασκευή μοντέρνων γρήγορων ιστοσελίδων - Rush speedy web apps'
			description='Κατασκευή e-shop, ιστοσελίδων. Εφαρμογές για κινητά, custom εφαρμογές, web applications'
			image={metaImage}
		//	image="/assets/image/rush-promo.jpg"
			pathname="/"
			website
		/>
		{/*    <Helmet
          htmlAttributes={{ lang: 'el' }}
          title="Κατασκευή μοντέρνων γρήγορων ιστοσελίδων - Rush"
          meta={[
            { name: 'description', content: 'Κατασκευή e-shop, Κατασκευή ιστοσελίδων, εφαρμογές για κινητά, custom εφαρμογές, webapplications' },
            { name: 'keywords', content: 'Κατασκευή e-shop, Κατασκευή ιστοσελίδων, εφαρμογές για κινητά, custom εφαρμογές, webapplications' },
			{ name: 'google-site-verification', content: 'Hx8Ona9t42pvK6oaHKBr3C5clqrFclYEE-zXFxc7YnI'},
          ]}
  ></Helmet>*/}
        <Banner isUserScrolled={this.setUserScrolled}  scrolled={scroll} />
		{/* <Welcome />*/}
			{scroll  ? <One /> : <section id="one"></section> }
		{/*	{ scroll ? <Two scrolled={scroll} /> : null }
  <Two scrolled={scroll} />*/}
  {/*      <Three />*/}
  {scroll  ?  <Four /> : null }
		{/*<Portfolio />*/}
		{/* <Five />*/}
			{/*<Contact />*/}
		{scroll  ?	<Lottie4 scroll={scroll} /> : null}
      </Layout>
    )
  }
}

export default Home

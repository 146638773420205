import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import Shapes from '../components/Shapes'
//import { useScroll } from '../hooks/useScrolling'

//import pic01 from '../assets/images/intro-img.png'
import { StaticImage } from 'gatsby-plugin-image'
import { Link as ScrollLink } from 'react-scroll'
//import Lottie from 'react-lottie-player'
//import LottieJson from '../assets/lotties/5167-graph-icon.json'

//KALO import Lottie1 from '../components/lottie1'
//import Lottie1 from '../components/lottie5'
//import { graphql } from 'gatsby';
//import { GatsbyImage } from '@wardpeet/gatsby-image-nextgen';
//import BackgroundSection from '../components/BackgroundSection'
import  Animated  from '../components/animated'
import { TextLoop } from 'react-text-loop-next'
//const Animated = loadable(() => import('../components/animated'))
const Lottie1 = loadable(() => import('../components/lottie1'))
//const Lottie1 = loadable(() => import('../components/lottieTest'))
//import Lottie1 from '../components/lottie1'

const Banner = (props) => {
	//console.log(props)
	//console.log(ref)
	//const startAnimation = props.scrolled && props.scrolled.scrollY > 8
//	const startAnimation = props.scrolled
//const Banner = ({data}) => {


const [startAnimation, setStartAnimation] = useState(false);
//const startAn = useScroll()
//console.log(startAn)
//const startAnimation = startAn.scrollY > 5
//const startAnimation = startAn.scrollY > 5 && startAn.scrollDirection !== 'undefined' && startAn.scrollDirection == 'up'
//console.log(startAnimation)
//props.isUserScrolled(startAnimation)

/*const [scrollTop, setScrollTop] = useState(0);
const [scrolling, setScrolling] = useState(false);
const [direction, setDirection] = useState(1);
*/
  useEffect(() => {
    window.onscroll = () => {
      setStartAnimation(true)
	  props.isUserScrolled(true)
    }
	 return () => {
        // this code fires on unmount! Perfect for our use case!
		//setStartAnimation(false)
		//props.isUserScrolled(false)
    }
  }, [startAnimation, props]);

    /* useEffect(() => {
		setStartAnimation(true)
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
	  scrollPercentRounded > 0 ? setDirection(-1): setDirection(1);
    };
	if (typeof window !== 'undefined') {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
	}
	//else return
  }, [scrollTop], [direction]);
  console.log(scrollTop)

 let scrollPercent = 0
 if (typeof document !== 'undefined') {
 scrollPercent = 100 * scrollTop / document.documentElement.clientHeight
 }
  //console.log(scrollPercent)
  const scrollPercentRounded = Math.round(scrollPercent)
  */
  //const ref = useRef()
 // useImperativeHandle(ref, () => ({getMyState: () => {return startAnimation}}), [startAnimation]);

/*  const imgStyle = {
	//  width: 200,
	  padding: 25,
	//  margin: 50,
}*/
  //console.log(data);
 // console.log(props)
return (
  <section id="banner">
{/*<BackgroundSection fileName="banner.jpg" >*/}
<Shapes />
	<div className="content">
      <header>
	  <h1>
	   {startAnimation &&
	   	  <React.Fragment>
	   <TextLoop adjustingSpeed="750" delay="100" springConfig={{stiffness: 120, damping: 30}}>
				  <span><Animated>Γρήγορες</Animated></span>
				  <span><Animated>Μοντέρνες</Animated></span>
				  <span><Animated>Μοντέρνα</Animated></span>
				  <span><Animated>Εύχρηστες</Animated></span>
				  <span><Animated>Επιτυχημένες</Animated></span>
				  <span><Animated>Γρήγορα</Animated></span>
				  <span><Animated>Επιτυχημένα</Animated></span>
			  </TextLoop>{'\n'}<br />
			  <TextLoop adjustingSpeed="5">
			  <span><Animated>ιστοσελίδες</Animated></span>
			  <span><Animated>εφαρμογές</Animated></span>
			  <span><Animated>e-shop</Animated></span>
			  <span><Animated>ιστοσελίδες</Animated></span>
			  <span><Animated>ιστοσελίδες</Animated></span>
			  <span><Animated>e-shop</Animated></span>
			  <span><Animated>e-shop</Animated></span>
			  </TextLoop>
		  </React.Fragment>
	   }
	  {!startAnimation &&
			  <span>Γρήγορες & Μοντέρνες{'\n'}<br /> ιστοσελίδες</span>
	  }
</h1>
        <p>
		{/* Το σημαντικότερο χαρακτηριστικό. */}
		Βρείτε όλα τα εφόδια
<br />
{" "}για να οδηγήσετε το project σας στην κορυφή.
        </p>
      </header>
      <span className="image">
	 {startAnimation && <Lottie1 scrolled={true} />}
      {!startAnimation && (
	/* <GatsbyImage
      placeholder={{ fallback: data.file.childImageSharp.fixed.fallback }}
      images={{
        fallback: {
          src: data.file.childImageSharp.fixed.src,
          srcSet: data.file.childImageSharp.fixed.srcSet,
        },
        sources: [
          {
            src: data.file.childImageSharp.fixed.srcWebp,
            srcSet: data.file.childImageSharp.fixed.srcSetWebp,
            type: 'image/webp',
          },
        ],
      }}
      width={data.file.childImageSharp.fixed.width}
      height={data.file.childImageSharp.fixed.height}
      layout="fixed"
      alt="intro image"
    />*/
    <StaticImage
    //  style={ imgStyle }
    style= {{margin: '2em'}}
      src="../assets/images/intro-img.png"
      alt="Rush intro"
      placeholder="blurred"
      layout="fixed"
      width={140}
      //height={55}
    />
	/*<img width='200' style={ imgStyle } src={pic01} alt="intro" />*/
      )}

		  {/*<img src={pic01} alt="intro" />*/}
      </span>
    </div>
    <ScrollLink
      to="one"
      href="/"
      onClick={() => (setStartAnimation(true) && props.isUserScrolled(true)) } // Trigger here to load loadable
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={25}
      duration={3000}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
  )
}

/*
export const query = graphql`
  query {
    file(relativePath: { eq: "intro-img.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 200, height: 190) {
          fallback: base64
          width
          height
          src
          srcSet
          srcWebp
          srcSetWebp
        }
      }
    }
  }
`;
*/
export default Banner
